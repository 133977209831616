<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5 pt-5 mb-5 d-flex justify-content-center pb-5">
                <div v-if="resetSuccess">
                    <h1 class="font-primary success-heading text-center mb-5 mt-5 pt-5">Password changed successfully!</h1>
                    <p class="auth-explanation-text">New password has been set. You can now <router-link style="text-decoration:underline" to='/login'>log in</router-link>.</p>
                </div>
                <div v-else class="new-pass-wrapper">
                    <h1 class="font-primary success-heading text-center mb-5 mt-5 pt-5">Email confirmed successfully!</h1>
                    
                    <div v-if="errors.length" class="error-wrapper d-flex align-items-center justify-content-center">
                        <p :key="error" v-for="error of errors" class="error-message">{{error}}</p>
                    </div>
                    
                    <form @submit.prevent="submitNewPassword" action="/" class="new-password-form">
                        <div class="form-group mb-0">
                            <label for="email">Email</label>
                            <input readonly :value="email" type="email">
                        </div>

                        <div class="form-group position-relative mb-0">
                            <label for="password">New password</label>
                            <input class="pe-5" @input="passwordError = null" v-model="newPassword" id="password" placeholder="New password" type="password">
                            <span v-if="passwordError" class="validation-error">{{passwordError}}</span>
                            <div class="info-wrapper">
                                <div class="info-icon">
                                    i
                                </div>
                                <div class="info-box">
                                    <p class="mb-0 pe-0">Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number and one special character.</p>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label for="confirm-password">Confirm new password</label>
                            <input @input="confirmPasswordError = null" v-model="confirmNewPassword" id="confirm-password" placeholder="Confirm new password" type="password">
                            <span v-if="confirmPasswordError" class="validation-error">{{confirmPasswordError}}</span>
                        </div>

                        <button @click="submitNewPassword" type="button" class="button mt-5 w-100">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import config from '../../config';

export default defineComponent({
    name: 'New Password',
    data: function() {
        return {
            newPassword: '',
            confirmNewPassword: '',
            confirmationToken: null,
            email: '',
            token: '',
            resetSuccess: false,
            passwordError: null,
            confirmPasswordError: null,
            accessToken: null,
            errors: []
        }
    },
    mounted() {
        const QueryString = window.location.search;
        const urlParams = new URLSearchParams(QueryString);
        this.email = urlParams.get('email');
        this.token = urlParams.get('token');
        this.$store.watch(state => {
            this.accessToken = state.accessToken;
        });
    },
    methods: {
        submitNewPassword() {
            if (!this.newPassword.length) {
                this.passwordError = 'Please enter a new password'
            } else if (!this.newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+_-])[A-Za-z\d@$!%*?&+_-]{8,20}$/)) {
                this.passwordError = 'Your password does not meet the requirements'
            } else {
                this.passwordError = null;
            }

            if (!this.confirmNewPassword.length) {
                this.confirmPasswordError = 'Please enter a new password'
            } else if (this.confirmNewPassword !== this.newPassword) {
                this.confirmPasswordError = 'Password confirmation does not match'
            } else {
                this.confirmPasswordError = null;
            }

            fetch(`${config.API}/api/v1/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: this.email.replaceAll(' ', '+'),
                    token: this.token,
                    password: this.newPassword,
                    password_confirmation: this.confirmNewPassword
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.errors) {
                    this.errors = data.errors;
                } else if (data.data.access_token) {
                    this.$toast.success('Password changed successfully');
                    this.resetSuccess = true;
                }
            })
            .catch(error => {
                console.log(error);
                this.$toast.error(error);
            });
        }
    }
});
</script>
